<template>
  <div>
    <div class="header-box fixed-top">
      <!-- <div class="header-hint">
        <p>
          <router-link to="/id/HongKongStockWarrants"
            >Warrants over Hong Kong listed technology stocks now listed in
            Malaysia. Click to learn more.
          </router-link>
        </p>
        <button type="button" class="close" id="hintBut">&times;</button>
      </div> -->
      <!-- 固定在浏览器顶部的导航栏 -->
      <nav class="navbar row header-navbat" id="navbarHead">
        <!-- Brand -->
        <router-link
          id="header-navber-logo"
          class="navbar-brand navbar-logo"
          to="/id/Home"
        >
          <img src="image/rhb_logo.png" alt width="100%" height="100%" />
        </router-link>
        <div class="col p-0 nav_dan">
          <ul class="navbar-ul row" style="padding-right: 0">
            <!-- <li class="col-1" style="padding: 0;"></li> -->
            <li class="nav-item dropdown col-sm" style="padding: 0">
              <div class="nav-link header-li-a" href="#" id="navbardrop">
                {{ $t("m.toolbox") }}
              </div>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="/id/WarrantMatrix">{{
                  $t("m.WarrantMatrix")
                }}</router-link>
                <router-link class="dropdown-item" to="/id/WarrantSearch">{{
                  $t("m.warrantSearch")
                }}</router-link>
                <router-link class="dropdown-item" to="/id/WarrantDetails">{{
                  $t("m.warrantDetails")
                }}</router-link>

                <router-link class="dropdown-item" to="/id/WarrantCalculator">{{
                  $t("m.warrantCalculator")
                }}</router-link>
                <router-link
                  class="dropdown-item"
                  to="/id/UnderlyingChart"
                  v-html="$t('m.underlyingChart')"
                ></router-link>
              </div>
            </li>
            <li
              class="nav-item dropdown col-sm"
              style="padding: 0; padding-right: 10px"
            >
              <div class="nav-link header-li-a" href="#" id="navbardrop">
                {{ $t("m.ideas") }}
              </div>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="/id/WarrantInFocus">{{
                  $t("m.warrantinFocus")
                }}</router-link>
                <router-link class="dropdown-item" to="/id/WarrantOfTheDay">{{
                  $t("m.warrantsoftheDay")
                }}</router-link>
                <router-link class="dropdown-item" to="/id/MarketMovers">{{
                  $t("m.marketMovers")
                }}</router-link>
                <!-- <router-link
                  class="dropdown-item"
                  to="/id/HongKongStockWarrants"
                  >{{ $t("m.hongKongStockWarrants") }}</router-link
                > -->
              </div>
            </li>
            <li
              class="nav-item dropdown col-sm"
              style="padding: 0; padding-right: 37px"
            >
              <div class="nav-link header-li-a" href="#" id="navbardrop">
                {{ $t("m.workshop") }}
              </div>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="/id/LearnWarrants">{{
                  $t("m.learnWarrants")
                }}</router-link>
                <router-link class="dropdown-item" to="/id/Seminar">{{
                  $t("m.seminar")
                }}</router-link>
                <router-link class="dropdown-item" to="/id/Campaign">{{
                  $t("m.campaign")
                }}</router-link>
                <router-link class="dropdown-item" to="/id/Gallery">{{
                  $t("m.gallery")
                }}</router-link>
                <router-link class="dropdown-item" to="/id/Video">{{
                  $t("m.video")
                }}</router-link>
              </div>
            </li>
            <li
              class="nav-item dropdown col-sm"
              style="padding: 0; padding-right: 30px"
            >
              <div class="nav-link header-li-a" href="#" id="navbardrop">
                {{ $t("m.announcement") }}
              </div>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="/id/WarrantShout-Out">{{
                  $t("m.warrantShoutOut")
                }}</router-link>
                <router-link class="dropdown-item" to="/id/NewWarrants">{{
                  $t("m.newWarrants")
                }}</router-link>
                <router-link class="dropdown-item" to="/id/ExpiringWarrants">{{
                  $t("m.expiringWarrants")
                }}</router-link>
                <router-link class="dropdown-item" to="/id/Document">{{
                  $t("m.warrantDocuments")
                }}</router-link>
              </div>
            </li>
            <li class="nav-item dropdown col-sm" style="padding: 0">
              <div class="nav-link header-li-a" href="#" id="navbardrop">
                {{ $t("m.connect") }}
              </div>
              <div class="dropdown-menu">
                <router-link class="dropdown-item" to="/id/SignMeUp">{{
                  $t("m.signMeUp")
                }}</router-link>
                <a
                  class="dropdown-item"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#RhbModal"
                  >{{ $t("m.rhbTradesmart") }}</a
                >
                <!-- <a class="dropdown-item" href="https://www.facebook.com/groups/MorningCuppa/" target="_blank">RHB Facebook
                Cuppa</a>-->
                <router-link class="dropdown-item" to="/id/Contactus">{{
                  $t("m.contactUs")
                }}</router-link>
                <!-- <a
                  class="dropdown-item"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#Instagram"
                  >{{ $t("m.instagram") }}</a
                > -->
                <a
                  class="dropdown-item"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#Telegram"
                  >{{ $t("m.telegramChannel") }}</a
                >
                <a
                  class="dropdown-item"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#TikTok"
                  >{{ $t("m.TikTok") }}</a
                >
                <!-- <router-link class="dropdown-item" to="/id/AntGroup"
                  >ANT GROUP</router-link
                > -->
              </div>
            </li>
          </ul>
        </div>
        <!-- <div class="HongKong_but">
        <router-link to="/id/HongKongStockWarrants">Hong Kong Stock Warrants now on Bursa Malaysia</router-link>
        </div>-->

        <!-- <div class="navbar-logo"></div> -->
        <div
          class="navbar-toggler"
          data-toggle="collapse"
          id="closebtn"
          style="border: none; transform: translateY(2px); cursor: pointer; display: none"
        >
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
        </div>
      </nav>
      <button type="button" class="disclaimer btn">
        <router-link class="footer-bold-a" to="/id/Disclaimer">{{
          $t("m.disclaimer")
        }}</router-link>
      </button>
      <button type="button" class="btn" id="language" @click.stop="languageS">
        {{ $t("m.language") }}
        <ul class="languageUl">
          <li @click.stop="changeLang('en-US', 1)">
            {{ $t("m.english") }}
          </li>
          <li @click.stop="changeLang('in-CN', 2)">
            {{ $t("m.indonesia") }}
          </li>
        </ul>
      </button>
    </div>

    <div
      id="mint"
      style="
        position: fixed;
        right: 0px;
        bottom: 0;
        z-index: 10000;
        background-color: #fff;
        width: 100%;
        height: 0;
      "
    >
      <nav
        class="navbar"
        style="
          padding: 0;
          display: none;
          height: 90vh;
          align-items: normal;
          flex-direction: column;
        "
        id="mint-nav"
      >
        <ul
          class="navbar-nav font_cand"
          style="width: 100%; padding-top: 15px; flex: 1; overflow-x: scroll"
        >
          <li class="nav-item index-mint-li" @click="clickNav()">
            <router-link class="nav-link index-mint-text" to="/id/Home">
              {{ $t("m.home") }}</router-link
            >
          </li>
          <li class="nav-item index-mint-li">
            <div
              class="nav-link index-mint-text"
              href="javascript:;"
              id="SecondLevelBut-one"
            >
              {{ $t("m.toolbox") }}
              <span class="index-mint-arrows index-mint-arrows-one">
                <span
                  class="bi bi-chevron-down"
                  aria-hidden="true"
                ></span>
              </span>
            </div>
            <ul
              class="navbar-nav"
              style="width: 100%; display: none"
              id="SecondLevel-one"
            >
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/id/WarrantMatrix"
                  >{{ $t("m.WarrantMatrix") }}</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/id/WarrantSearch"
                  >{{ $t("m.warrantSearch") }}</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/id/WarrantDetails"
                  >{{ $t("m.warrantDetails") }}</router-link
                >
              </li>

              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/id/WarrantCalculator"
                  >{{ $t("m.warrantCalculator") }}</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/id/UnderlyingChart"
                  v-html="$t('m.underlyingChart')"
                ></router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item index-mint-li">
            <div
              class="nav-link index-mint-text"
              href="javascript:;"
              id="SecondLevelBut-two"
            >
              {{ $t("m.ideas") }}
              <span class="index-mint-arrows index-mint-arrows-two">
                <span
                  class="bi bi-chevron-down"
                  aria-hidden="true"
                ></span>
              </span>
            </div>
            <ul
              class="navbar-nav"
              style="width: 100%; display: none"
              id="SecondLevel-two"
            >
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/id/WarrantInFocus"
                  >{{ $t("m.warrantinFocus") }}</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/id/WarrantOfTheDay"
                  >{{ $t("m.warrantsoftheDay") }}</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/id/MarketMovers"
                  >{{ $t("m.marketMovers") }}</router-link
                >
              </li>
              <!-- <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/id/HongKongStockWarrants"
                  >{{ $t("m.hongKongStockWarrants") }}</router-link
                >
              </li> -->
            </ul>
          </li>
          <li class="nav-item index-mint-li">
            <div
              class="nav-link index-mint-text"
              href="javascript:;"
              id="SecondLevelBut-three"
            >
              {{ $t("m.workshop") }}
              <span class="index-mint-arrows index-mint-arrows-three">
                <span
                  class="bi bi-chevron-down"
                  aria-hidden="true"
                ></span>
              </span>
            </div>
            <ul
              class="navbar-nav"
              style="width: 100%; display: none"
              id="SecondLevel-three"
            >
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/id/LearnWarrants"
                  >{{ $t("m.learnWarrants") }}</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link class="nav-link index-mint-text2" to="/id/Seminar"
                  >{{ $t("m.seminar") }}
                </router-link>
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link class="nav-link index-mint-text2" to="/id/Campaign"
                  >{{ $t("m.campaign") }}
                </router-link>
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link class="nav-link index-mint-text2" to="/id/Gallery"
                  >{{ $t("m.gallery") }}
                </router-link>
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link class="nav-link index-mint-text2" to="/id/Video">{{
                  $t("m.video")
                }}</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item index-mint-li">
            <div
              class="nav-link index-mint-text"
              href="javascript:;"
              id="SecondLevelBut-four"
            >
              {{ $t("m.announcement") }}
              <span class="index-mint-arrows index-mint-arrows-four">
                <span
                  class="bi bi-chevron-down"
                  aria-hidden="true"
                ></span>
              </span>
            </div>
            <ul
              class="navbar-nav"
              style="width: 100%; display: none"
              id="SecondLevel-four"
            >
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/id/WarrantShout-Out"
                  >{{ $t("m.warrantShoutOut") }}</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/id/NewWarrants"
                  >{{ $t("m.newWarrants") }}</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/id/ExpiringWarrants"
                  >{{ $t("m.expiringWarrants") }}</router-link
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/id/Document"
                  >{{ $t("m.warrantDocuments") }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="nav-item index-mint-li">
            <a
              class="nav-link index-mint-text"
              href="javascript:;"
              id="SecondLevelBut-five"
            >
              {{ $t("m.connect") }}
              <span class="index-mint-arrows index-mint-arrows-five">
                <span
                  class="bi bi-chevron-down"
                  aria-hidden="true"
                ></span>
              </span>
            </a>
            <ul
              class="navbar-nav"
              style="width: 100%; display: none"
              id="SecondLevel-five"
            >
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link class="nav-link index-mint-text2" to="/id/SignMeUp"
                  >{{ $t("m.signMeUp") }}
                </router-link>
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <a
                  class="nav-link index-mint-text2"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#RhbModal"
                  >{{ $t("m.rhbTradesmart") }}</a
                >
              </li>

              <li class="nav-item index-mint-li2" @click="clickNav()">
                <router-link
                  class="nav-link index-mint-text2"
                  to="/id/Contactus"
                  >{{ $t("m.contactUs") }}</router-link
                >
              </li>
              <!-- <li class="nav-item index-mint-li2" @click="clickNav()">
                <a
                  class="nav-link index-mint-text2"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#Instagram"
                  >{{ $t("m.instagram") }}</a
                >
              </li> -->

              <li class="nav-item index-mint-li2" @click="clickNav()">
                <a
                  class="nav-link index-mint-text2"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#Telegram"
                  >{{ $t("m.telegramChannel") }}</a
                >
              </li>
              <li class="nav-item index-mint-li2" @click="clickNav()">
                <a
                  class="nav-link index-mint-text2"
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#TikTok"
                  >{{ $t("m.TikTok") }}</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
    <div class="modal fade" id="RhbModal">
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 900px; max-height: 450px"
      >
        <div class="modal-content">
          <button type="button" class="close Rhb_close" data-dismiss="modal">
            &times;
          </button>
          <!-- https://www.rhbtradesmart.com -->
          <!-- 模态框主体 -->
          <a href="https://rhbtradesmart.co.id/download-new" target="_blank">
            <picture>
              <!-- <source srcset="/image/rhb_img.webp" type="image/webp" /> -->
              <img src="/image/Tradesmart.png" alt width="100%" />
            </picture>
          </a>
        </div>
      </div>
    </div>
    <div class="modal fade" id="Instagram">
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 900px; max-height: 450px"
      >
        <div class="modal-content">
          <button type="button" class="close Rhb_close" data-dismiss="modal">
            &times;
          </button>
          <a href="https://www.instagram.com/indonesiawarrant/" target="_blank">
            <picture>
              <!-- <source srcset="../../../public/image/telegram_img.webp" type="image/webp" /> -->
              <img
                src="../../../public/image/Instagram.png"
                alt="banner"
                width="100%"
              />
            </picture>
          </a>
        </div>
      </div>
    </div>
    <div class="modal fade" id="Telegram">
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 900px; max-height: 450px"
      >
        <div class="modal-content">
          <button type="button" class="close Rhb_close" data-dismiss="modal">
            &times;
          </button>
          <a href="https://t.me/Indonesiawarrants" target="_blank">
            <!-- <source
              srcset="../../../public/image/telegram_img.webp"
              type="image/webp"
            /> -->
            <!-- <img
              src="../../../public/image/telegram_img.webp"
              alt
              width="100%"
            /> -->
            <picture>
              <!-- <source srcset="../../../public/image/telegram_img.webp" type="image/webp" /> -->
              <img
                src="../../../public/image/Telegram.png"
                alt="banner"
                width="100%"
              />
            </picture>
          </a>
        </div>
      </div>
    </div>
    <div class="modal fade" id="TikTok">
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 900px; max-height: 450px"
      >
        <div class="modal-content">
          <button type="button" class="close Rhb_close" data-dismiss="modal">
            &times;
          </button>
          <a href="https://www.tiktok.com/@indonesiawarrant" target="_blank">
            <!-- <source
              srcset="../../../public/image/telegram_img.webp"
              type="image/webp"
            /> -->
            <!-- <img
              src="../../../public/image/telegram_img.webp"
              alt
              width="100%"
            /> -->
            <picture>
              <!-- <source srcset="../../../public/image/telegram_img.webp" type="image/webp" /> -->
              <img
                src="../../../public/image/TikTok.png"
                alt="banner"
                width="100%"
              />
            </picture>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { headerIncident } from "./Header.js";
export default {
  name: "Header",
  inject: ["reload"],
  created() {},
  mounted() {
    let _this = this;
    this.$nextTick(function() {
      headerIncident();
      _this.$js.ScreenMonitor();
      // window.sessionStorage.clear()
      if (window.sessionStorage.getItem("language") == null) {
        this.changeLang("in-CN", 2);
      }
    });
  },
  methods: {
    // 点击菜单(手机)
    clickNav: function() {
      $("#mint").css({ height: "0" });
      $("#mint-nav").slideUp();
      $(".navbar-toggler").removeClass("is--active");
    },
    languageS: function() {
      $(".languageUl").toggle();
    },
    changeLang(language, lang) {
      if (language === "in-CN") {
        this.$i18n.locale = language;
        window.sessionStorage.setItem("language", language);
        window.sessionStorage.setItem("lang", lang);
      } else {
        this.$i18n.locale = language;
        window.sessionStorage.setItem("language", language);
        window.sessionStorage.setItem("lang", lang);
      }
      $(".languageUl").hide();
      this.reload();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
@import './Header.css';
</style>
